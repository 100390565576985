import React, {useState, useEffect} from 'react';
import logo from '../assets/image/pk_logo-100_x_100.png'
import './MainHeader.css';
import ContactInfoModel from './ContactInfoModel.js';
import '@material/web/button/elevated-button.js'
import Resume from '../pratish_karmacharya_resume_android.pdf';

const MainHeader = () => {
    let info = ContactInfoModel;

    const [isDivVisible, setIsDivVisible] = useState(false)
    const [isHovered, setIsHovered] = useState(false)

    useEffect(
        () => {
            const handleScroll = () => {
                const divElement = document.getElementById('home');
                if (divElement) {
                    const bottom = divElement.getBoundingClientRect().bottom;
                    setIsDivVisible(bottom < 90);
                }   
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, []);

    const backgroundStyle = {
        background: isDivVisible ? '#333' : 'white',
        transition: 'background 1s ease',
    }

    const fontColor = {
        transition: 'color 1s ease',
        color: isDivVisible ? 'white' : '#333',
    }

    

    const handleDownload = () => {
        // Replace 'your-pdf-file.pdf' with the path to your PDF file
        const link = document.createElement('a');
        link.href = Resume;
        link.setAttribute('download', 'pratish_karmacharya_resume.pdf'); // Set the desired file name for download
        document.body.appendChild(link);
        link.click();
    };

    const handleHover = () => {
        setIsHovered(!isHovered);
        console.log("pring me damnit: " + isHovered)
    }

    return (
    <div className="mainheader">        
        <div style={backgroundStyle} className='menu'>
            <a style={fontColor} href="#home">Home</a>
            <a style={fontColor}href="#about">About</a>
            <a style={fontColor}href="#projects">Projects</a>
            <a style={fontColor} onMouseEnter={handleHover} onMouseLeave={handleHover}  onClick={handleDownload}>Download Resume</a>
            <a style={fontColor} href="#contact">Contact</a>
        </div>
    </div>
    )
}

export default MainHeader;
