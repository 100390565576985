import React from 'react';
import './Experience.css';
import CollapsibleParagraph from '../Components/CollapsibleParagraph.js';
import Tags from '../Components/Tags.js'


const Experience = () => {
    let experiences = ExperienceModels;
    return (
        <div id="projects" className='experience'>
            <h1 >projects.</h1>
            <div className='experiences'>
                {
                    experiences.map((item, index) => (
                        <div key={index} className='experience-detail'>
                            <div className='title'>
                                <h2>{item.title}</h2>
                                {/* <div className='tags'><Tags items={item.technology}/></div> */}
                            </div>
                            <p><em>{item.technology.join(', ')}</em></p>
                            {/* <CollapsibleParagraph text={item.summary} linesToShow={3} /> */}
                            <p>{item.summary}</p>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default Experience;

const ExperienceModels = [
    {
        company: "Brother Mobile Solutions",
        title: "Android Development Mentor",
        position: "Android Development Mentor",
        location: "Westminster, CO",
        date: "2023 - Present",
        technology: ["kotlin", "coroutine", "jetpack compose"],
        summary: "Recently, I had an amazing opportunity to mentor some incredibly talented developers at Brother Mobile Solution. My role involved guiding them in crafting strong, reliable mobile applications by focusing on design principles that are both scalable and testable. We delved into Q&A sessions and thorough post-analysis to highlight the importance of SOLID principles and how they truly matter in today's software development landscape. \nMy expertise revolves around conducting in-depth analyses and case studies on SOLID principles, integrating key concepts such as separation of concerns. Being able to contribute and give back to the community in this way has been immensely rewarding for me."  
    },
    {
        company: "Derive Systems",
        title: "Vehicle Tuner App (Android)",
        position: "Software Engineer II",
        location: "Broomfield, CO",
        date: "2019 - 2023",
        technology: ["kotlin", "jetpack compose", "coroutine", "hilt", "okhttp", "BLE"],
        summary: "Led the development of a multi-module android application enabling secure vehicle ECU reprogramming via Bluetooth, Wi-Fi, and USB interfaces. Connected the app to a store-bought 3rd-party hardware via USB while also handling proprietary hardware to gather vehicle information. \nUsed the vehicle information to securely retrieve data from the cloud, which was then utilized for ECU reprogramming.\n\nThe hardware-stack was written using Coroutine to utilize `channels` for processing huge amount of data. While the UI stack was written using Jetpack Compose. Hilt was used for dependency injection."
    },
    {
        company: "Derive Systems",
        title: "Tune File Generator (MacOS)",
        position: "Technology Architect",
        location: "Minneapolis, MN",
        date: "11/2018 - 07/2019",
        technology: ["Swift", "SwiftUI", "MVVM"],
        summary: "The Tune File Generator is a complementary application for the Tuner App. This application was written in Swift using SwiftUI. It is capable of reading existing tunes and analyzing the data to provide a series of steps required to reprogram the ECU.\nAdditionally, it can parse existing stock ECU binaries obtained from Ford and generate all the necessary information used by the Tuner app. ",
    },
    {
        company: "Derive Systems",
        title: "Driver Safety App (Android)",
        position: "Software Engineer II",
        location: "Broomfield, CO",
        technology: ["kotlin", "coroutine", "dagger2", "channels", "okhttp"],
        date: "2019 - 2023",
        summary: "At Derive Systems, I developed a Driver Safety app specifically tailored for fleet vehicle drivers. Alongside this app, I created a complementary hardware integration library. The application was distributed within a Mobile Device Management (MDM) system, effectively restricting mobile device usage while the vehicle was in motion.\nMoreover, the app provided real-time location tracking of the driver, allowing fleet managers to monitor their drivers' whereabouts. To achieve its functionalities, the app utilized Dagger2 for dependency injection, Kotlin coroutines, and channels for asynchronous data transmission. Communication with backend services for driver profile synchronization was facilitated through OkHttp. Additionally, the app seamlessly integrated with MapQuest, offering navigation options to users.\nFurthermore, if Speed Limit data was enforced on the road, the app had a governor feature that limited the vehicle's speed to the specified limit."
    },
    {
        company: "Open Access Technology International Inc.",
        title: "Home Automation App (iOS)",
        position: "Lead Mobile Developer",
        location: "Minneapolis, MN",
        technology: ["objective c", "core data", "mvvm", "Wi-Fi", "BLE"],
        date: "",
        summary: "The home automation app was specifically designed to integrate seamlessly with a thermostat controller. Through collaboration with utility providers, the app had the ability to slightly lower the temperature setpoint, thereby assisting homeowners in saving a portion of their electric bill."
    },
    {
        company: "Open Access Technology International Inc.",
        title: "webSupport Mobile (iOS/Android)",
        position: "Lead Mobile Developer",
        location: "Minneapolis, MN",
        technology: ["objective c", "Java", "mvvm", "SOAP"],
        date: "",
        summary: "WebSupport Mobile was developed as a dual-platform application for both iOS and Android, serving as a support system for the company. The app allowed employees to request Paid Time Off (PTO) conveniently. Additionally, during company-related events, the application offered a program schedule and provided attendees with the capability to seek assistance or ask questions from the organizers as needed."
    },
    {
        company: "Open Access Technology International Inc.",
        title: "webTraderMobile (Android)",
        position: "Lead Mobile Developer",
        location: "Minneapolis, MN",
        technology: ["Java", "mvvm", "SOAP"],
        date: "",
        summary: "webTraderMobile is a complimentary application for the webTrader web application. The application allowed the customers to view real time trading data for power transmission lines."
    }
]