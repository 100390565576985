import React, {useState} from 'react';
// Material.IO
import '@material/web/button/text-button.js';

const CollapsibleParagraph = ({text, linesToShow }) => {
    const [showFull, setShowFull] = useState(false);

    const toggleShowFull = () => {
        setShowFull(!showFull);
    };

    return (
        <div>
            <p
                style={{
                    overflow: 'hidden',
                    textOverflow: showFull ? 'unset' : 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: showFull ? 'unset' : linesToShow,
                    WebkitBoxOrient: 'vertical',
                    color: 'white',
                }}
            >
                {text}
            </p>
            {!showFull &&  (
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <md-text-button onClick={toggleShowFull}>See more</md-text-button>
                </div>
            )}
        </div>
    );
};

export default CollapsibleParagraph;