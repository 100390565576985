import React from 'react';
import './Home.css';

const Home = () => {
    return (
        <div id='home' className='home'>
            <div className='content'>
                <div className='title'>Hi, my name is</div>
                <div className='name'>Pratish.</div>
                <div className='summary'>a software engineer & architect,</div>
                <div className='catch'>turning ideas into mobile solutions, one problem at a time.</div>
            </div>
        </div>
    )

}

export default Home;