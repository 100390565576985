// import backgroundImage from './mountain_background.jpg'
import './App.css';
import MainHeader from './content/MainHeader';
import AboutMe from './content/AboutMe';
import Experience from './content/Experience';
import Home from './content/Home';

<script type="module" src="./index.js"></script>

function App() {
  return (
      <div className="App">
        <div className='fixed-header'><MainHeader /></div>
        <div className='main-body'>
            <div className='main-home'><Home /></div>
            <div className='content'>
              <AboutMe />
              <Experience />
            </div>
            <div id='contact' className='footer'>
              <div className="content">
                  <h1>Pratish Karmacharya</h1>
                  <h3>Email: 01-burrow-onetime@icloud.com</h3>
              </div>
            </div>
        </div> 
    </div>
  );
}


export default App;
