import React, { useState, useEffect } from 'react';
import './AboutMe.css'
import '@material/web/progress/linear-progress'
import ProgressIndicator from '../Components/ProgressIndicator';


const AboutMe = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      // Simulating progress with intervals
      const interval = setInterval(() => {
        // Increment progress by a certain value
        setProgress(prevProgress => {
          const newProgress = prevProgress + 10;
          return newProgress <= 100 ? newProgress : 100;
        });
      }, 1000); // Change the interval time as needed
  
      return () => clearInterval(interval);
    }, []);

    return (
        <div className='about' id='about'>
            <h1>about me.</h1>
            <div className='content'>
                <div className='paragraph'><p>Hi, I'm Pratish Karmacharya, a Mobile Engineer and Software Architect passionate about integrating hardware into mobile apps.</p></div>
                {/* <div className='photo'></div> */}
            </div>
    
          <h1>skills.</h1>
        
          <div className="skills">
            <div>
            <div className="progress">
              <span className="skill">Android SDK <i className="val"></i></span>
              <div className="progress-bar-wrap">
                <ProgressIndicator value='100' />
              </div>
            </div>
            <div className="progress">
              <span className="skill">Kotlin <i className="val"></i></span>
              <div className="progress-bar-wrap">
                <ProgressIndicator value="90" />
              </div>
            </div>
            <div className="progress">
              <span className="skill">Jetpack Compose <i className="val"></i></span>
              <div className="progress-bar-wrap">
                <ProgressIndicator value="90" />
              </div>
            </div>
            <div className="progress">
              <span className="skill">Kotlin Coroutine <i className="val"></i></span>
              <div className="progress-bar-wrap">
              <ProgressIndicator value="90" />
              </div>
            </div>
            </div>

            <div>
            <div className="progress">
                <span className="skill">iOS SDK <i className="val"></i></span>
                <div className="progress-bar-wrap">
                <ProgressIndicator value="90" />
                </div>
              </div>
              <div className="progress">
                <span className="skill">Swift <i className="val"></i></span>
                <div className="progress-bar-wrap">
                <ProgressIndicator value="80" />
                </div>
              </div>
              <div className="progress">
                <span className="skill">SwiftUI <i className="val"></i></span>
                <div className="progress-bar-wrap">
                <ProgressIndicator value="80" />
                </div>
              </div>
            </div>
          </div>
      </div>
    )
}

export default AboutMe;
