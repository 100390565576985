import React, { useState, useEffect } from 'react';
import '@material/web/progress/linear-progress'

const ProgressIndicator = ({value}) => {
    const [progress, setProgress] = useState(0);
    let max = parseInt(value, 10);
    useEffect(() => {
    // Simulating progress with intervals
    setTimeout( () => {
    const interval = setInterval(() => {
      // Increment progress by a certain value
      setProgress(prevProgress => {
        const newProgress = prevProgress + 1;
        return newProgress <= value ? newProgress : max;
      });
    }, 10); // Change the interval time as needed

    return () => clearInterval(interval);
    }, 1000);
  }, []);

  return (
    <div>
        <md-linear-progress value={progress/100}></md-linear-progress>
    </div>
  );
};

export default ProgressIndicator;
